<script>
/* eslint-disable */
import {FormWizard, TabContent} from '@anivive/vue3-form-wizard';

import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Form wizard component
 */
export default {
  page: {
    title: 'Form Wizard',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {
    Layout, PageHeader, FormWizard, TabContent,
  },
  data() {
    return {
      title: 'Form Wizard',
      items: [
        {
          text: 'Forms',
          href: '/',
        },
        {
          text: 'Form Wizard',
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic Wizard</h4>
            <form-wizard color="#556ee6">
              <tab-content icon="mdi mdi-account-circle">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="userName"
                      >User name</label
                      >
                      <div class="col-md-9">
                        <input
                          id="userName"
                          class="form-control"
                          name="userName"
                          type="text"
                          value="Themesbrand"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="password"
                      >Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="password"
                          class="form-control"
                          name="password"
                          type="password"
                          value="123456789"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="confirmpass"
                      >Re Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="confirmpass"
                          class="form-control"
                          name="confirmpass"
                          type="password"
                          value="123456789"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-face-profile">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                      >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="name"
                          type="text"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                      >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="surname"
                          type="text"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                      >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="email"
                          type="email"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Thank you !</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Quisque nec turpis at urna dictum luctus. Suspendisse
                        convallis dignissim eros at volutpat. In egestas mattis
                        dui. Aliquam mattis dictum aliquet.
                      </p>

                      <div class="mb-3">
                        <b-form-checkbox
                          id="customControlInline"
                          class="form-check"
                          name="checkbox-1"
                          unchecked-value="not_accepted"
                          value="accepted"
                        >
                          Remember me
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Tab Wizard</h4>
            <form-wizard color="#556ee6" shape="tab">
              <tab-content icon="mdi mdi-account-circle">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                      >User name</label
                      >
                      <div class="col-md-9">
                        <input
                          id="name"
                          class="form-control"
                          type="text"
                          value="Themesbrand"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="pwd"
                      >Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="pwd"
                          class="form-control"
                          name="pwd"
                          type="password"
                          value="123456789"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="confirmpwd"
                      >Re Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="confirmpwd"
                          class="form-control"
                          type="password"
                          value="123456789"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-face-profile">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                      >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="name"
                          type="text"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                      >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="surname"
                          type="text"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                      >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          class="form-control"
                          name="email"
                          type="email"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Thank you !</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Quisque nec turpis at urna dictum luctus. Suspendisse
                        convallis dignissim eros at volutpat. In egestas mattis
                        dui. Aliquam mattis dictum aliquet.
                      </p>

                      <div class="mb-3">
                        <b-form-checkbox
                          id="customCheck1"
                          class="form-check"
                          name="checkbox-1"
                          unchecked-value="not_accepted"
                          value="accepted"
                        >
                          I agree with the Terms and Conditions
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </tab-content>
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
